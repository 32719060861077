export const listJobOpportunitys = /* GraphQL */ `
  query ListJobOpportunitys(
    $filter: ModelJobOpportunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobOpportunitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        customer {
          id
          family_name
          given_name
          username
          companyDetails {
            name
          }
        }
        customerOwner {
          id
          username
        }
        geographicalRegions {
          regionName
        }
        externalJobId
        jobLength
        jobLengthInWeeks
        jobType {
          id
          title
        }
        matches(limit: 1000) {
          items {
            status
            subStatus
          }
        }
        maxRate {
          value
        }
        minRate {
          value
        }
        optionalSkills {
          id
          name
        }
        organization
        priorityLevel
        skills {
          id
          name
        }
        status
        title
        torcOwner {
          id
          username
        }
        workMode
      }
      nextToken
    }
  }
`;
export const getJobOpportunity = /* GraphQL */ `
  query GetJobOpportunity($id: ID!) {
    getJobOpportunity(id: $id) {
      organization
      calibrationIsEnabled
      customer {
        company
        companyDetails {
          name
        }
        notes {
          items {
            id
          }
        }
      }
      externalJobId
      freelancerPitchPrefill
      torcOwner {
        username
      }
      id
      notesCount
      jobLength
      jobLengthInWeeks
      jobType {
        id
        title
      }
      maxRate {
        value
      }
      minRate {
        value
      }
      optionalSkills {
        id
        name
      }
      overview
      priorityLevel
      requirements
      responsibilities
      skills {
        id
        name
      }
      startDate
      status
      timeOverlap
      timeCommitment
      timezone {
        label
        value
      }
      title
      location {
        cityName
        countryName
        stateName
        latitude
        longitude
        locationId
      }
      matches(limit: 1000) {
        items {
          id
          applicationId
          id
          analysis {
            score
            report
          }
          availableStartDate
          createdAt
          creator
          customerSummary
          calibrationRate {
            value
            currency
          }
          customerRate {
            value
          }
          freelancerPitch
          freelancerPitchPrefill
          isAccepted
          isCalibration
          jobOpportunityId
          metadata
          notesCount
          rate {
            value
          }
          rating
          reasonsForRating
          status
          subStatus
          userId
          updater
          updatedAt
          user {
            assessments {
              id
              codeScore
              finalScore
              multipleChoiceScore
              reportLink
              status
              testName
            }
            createdAt
            careers {
              id
              companyName
              description
              endDate
              format
              locationType
              stack
              startDate
              title
            }
            educationRecords {
              id
              degreeType
              degreeName
              description
              endDate
              fieldOfStudy
              school
              startDate
            }
            email
            headshotKey
            username
            id
            talentSource
            additionalMarkup
            availability
            noticePeriod
            family_name
            given_name
            identity_username
            isUnclaimed
            location {
              cityName
              countryCode
              countryName
              stateName
            }
            profileCompletion
            metadata
            ratePerHour {
              currency
              value
            }
            phone {
              number
              whatsAppAllowed
            }
            placements {
              id
              actualEndDate
              endDate
            }
            projectsCaseStudies {
              id
              client
              description
              endDate
              workType
              link
              stack
              startDate
              title
            }
            referrerCode
            skills {
              id
              experience
              name
            }
            knownLanguages {
              level
              language
            }
            placements {
              id
              actualEndDate
              createdAt
              companyName
              endDate
              jobOpportunityId
              startDate
              updatedAt
            }
            socialLinks {
              type
              value
            }
            status
            otherLinks {
              description
              name
              type
              value
              visibility
            }
            resumeLocation
            torcQualityStats
            workAuthorizations
          }
          userId
        }
        nextToken
      }
      geographicalRegions {
        regionName
        countryNames
      }
      workMode
    }
  }
`;

export const listSimilarJobOpportunities = /* GraphQL */ `
  query ListSimilarJobOpportunities(
    $query: String!
    $limit: Int = 5
    $preFilter: ModelSimilarJobOpportunityPreFilterInput
    $filter: ModelSimilarJobOpportunityFilterInput
    $matchFilter: ModelMatchFilterInput
    $matchNexToken: String
  ) {
    listSimilarJobOpportunities(
      query: $query
      limit: $limit
      preFilter: $preFilter
      filter: $filter
    ) {
      id
      customer {
        company
        companyDetails {
          name
        }
      }
      jobLength
      jobLengthInWeeks
      jobType {
        id
        title
      }
      maxRate {
        value
      }
      minRate {
        value
      }
      matches(filter: $matchFilter, nextToken: $matchNexToken) {
        items {
          applicationId
          user {
            applications {
              items {
                isNotActive
                jobTypeId
                id
              }
            }
          }
          jobOpportunityId
          status
          subStatus
          userId
        }
        nextToken
      }
      optionalSkills {
        id
        name
      }
      organization
      skills {
        name
      }
      overview
      requirements
      responsibilities
      status
      timeOverlap
      timezone {
        label
        value
      }
      title
      vectorSearchScore
    }
  }
`;
export const getMatch = /* GraphQL */ `
  query GetMatch($applicationId: ID!, $jobOpportunityId: ID!) {
    getMatch(
      applicationId: $applicationId
      jobOpportunityId: $jobOpportunityId
    ) {
      analysis {
        score
        report
      }
      applicationId
      customerSummary
      jobOpportunityId
      isAccepted
      status
      subStatus
      rate {
        value
      }
      userId
    }
  }
`;
export const listJobOpportunityMatches = /* GraphQL */ `
  query GetJobOpportunity(
    $id: ID!
    $matchFilter: ModelMatchFilterInput
    $matchNexToken: String
  ) {
    getJobOpportunity(id: $id) {
      id
      matches(filter: $matchFilter, nextToken: $matchNexToken) {
        items {
          applicationId
          user {
            applications {
              items {
                isNotActive
                id
              }
            }
          }
          jobOpportunityId
          status
          subStatus
          userId
        }
        nextToken
      }
    }
  }
`;
export const listJobOpportunityNotes = /* GraphQL */ `
  query ListJobOpportunityNotes(
    $jobOpportunityId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobOpportunityNotes(
      jobOpportunityId: $jobOpportunityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationId
        content
        createdAt
        creator
        isPublic
        noteType
        userId
      }
      nextToken
    }
  }
`;
export const listUserNotes = /* GraphQL */ `
  query ListUserNotes(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotes(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        creator
        isPublic
        createdAt
        applicationId
        userId
      }
      nextToken
    }
  }
`;
export const listMatchNotes = /* GraphQL */ `
  query ListMatchNotes(
    $applicationId: ID
    $jobOpportunityId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMatchNotes(
      applicationId: $applicationId
      jobOpportunityId: $jobOpportunityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        creator
        isPublic
        createdAt
        applicationId
        userId
        noteType
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      notes {
        items {
          content
          creator
          isPublic
          createdAt
        }
      }
    }
  }
`;
export const getFieldHistory = /* GraphQL */ `
  query GetFieldHistory(
    $entity: String
    $key: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFieldHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFieldHistory(
      entity: $entity
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        oldValue
        newValue
        creator
        createdAt
      }
    }
  }
`;
export const getUserActivityHistory = /* GraphQL */ `
  query GetUserActivityHistory(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserActivityHistory(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        eventName
        message
        createdAt
      }
      nextToken
    }
  }
`;
export const generateMatchAnalysis = /* GraphQL */ `
  query GenerateMatchAnalysis(
    $jobOpportunityId: String!
    $userId: String!
    $additionalDetail: String
    $noCache: Boolean
  ) {
    generateMatchAnalysis(
      jobOpportunityId: $jobOpportunityId
      userId: $userId
      additionalDetail: $additionalDetail
      noCache: $noCache
    ) {
      summary
      score
      report
    }
  }
`;
export const getUserByUsername = /* GraphQL */ `
  query GetUserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
      }
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
      }
    }
  }
`;

export const getJobOpportunityEvents = /* GraphQL */ `
  query GetJobOpportunityEvents($id: ID!) {
    getJobOpportunityEvents(id: $id) {
      conferencing {
        provider
        details {
          password
          url
          meetingCode
        }
      }
      description
      end
      owner
      participants {
        email
        name
        status
      }
      start
      status
      title
    }
  }
`;

export const getCloudinarySignature = /* GraphQL */ `
  query GetCloudinarySignature($publicId: String!, $preset: String) {
    getCloudinarySignature(publicId: $publicId, preset: $preset) {
      signature
      timestamp
      apiKey
    }
  }
`;

export const listCoderbyteAssessments = /* GraphQL */ `
  query ListCoderbyteAssessments {
    listCoderbyteAssessments {
      isClosed
      link
      testId
      testName
    }
  }
`;

export const listSharedAssessmentsByUser = /* GraphQL */ `
  query ListSharedAssessmentsByUser(
    $userId: ID
    $testId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSharedAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSharedAssessmentsByUser(
      userId: $userId
      testId: $testId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobOpportunityId
        link
        testId
        testName
        userId
      }
      nextToken
    }
  }
`;

export const getMe = /* GraphQL */ `
  query GetMe {
    getMe {
      id
      address {
        city
        country
        line1
        line2
        postalCode
        state
      }
      agreedToTerms
      agreedToMarketing
      company
      companyDetails {
        address {
          city
          country
          line1
          line2
          postalCode
          state
        }
        billingEmail
        bio
        logo
        name
        tagline
        timezone {
          label
          value
        }
        url
      }
      coverPhoto
      creator
      displayName
      email
      family_name
      given_name
      headshotKey
      identity_username
      isVip
      jobRole
      locale
      location {
        cityName
        countryCode
        countryId
        countryName
        latitude
        locationId
        longitude
        stateCode
        stateId
        stateName
        wikiDataId
      }
      metadata
      otherLinks {
        createdAt
        creator
        description
        name
        type
        updatedAt
        updater
        value
        visibility
      }
      phone {
        number
        whatsAppAllowed
      }
      profileCompletion
      profileStats
      referralCode
      referralCount
      referrerCode
      referrerSource
      shirtSize
      socialLinks {
        type
        value
      }
      status
      tagline
      updater
      username
      userType
      visibility
    }
  }
`;

export const getCognitoGroupsForUser = /* GraphQL */ `
  query GetCognitoGroupsForUser($username: String!) {
    getCognitoGroupsForUser(username: $username) {
      groups
    }
  }
`;

export const getUserByReferralCode = /* GraphQL */ `
  query GetUserByReferralCode($referralCode: String!) {
    getUserByReferralCode(referralCode: $referralCode) {
      items {
        id
        username
        userType
      }
    }
  }
`;

export const searchViaAlgolia = /* GraphQL */ `
  query SearchViaAlgolia($requests: String!, $sffv: Boolean) {
    searchViaAlgolia(requests: $requests, sffv: $sffv)
  }
`;

export const getApplicationsForUser = /* GraphQL */ `
  query GetApplicationsForUser($userId: ID!, $nextToken: String) {
    getApplicationsForUser(userId: $userId, nextToken: $nextToken) {
      items {
        id
        isNotActive
      }
      nextToken
    }
  }
`;

export const getUserMatches = /* GraphQL */ `
  query GetUserMatches($userId: ID!, $nextToken: String) {
    getUserMatches(userId: $userId, nextToken: $nextToken) {
      items {
        id
        applicationId
        freelancerPitch
        jobOpportunity {
          id
          organization
          status
          title
        }
        jobOpportunityId
        rate {
          value
        }
        status
        createdAt
        updatedAt
        reasonForAutoMatch
      }
      nextToken
    }
  }
`;

export const listIdealCandidatesForJob = /* GraphQL */ `
  query ListIdealCandidatesForJob(
    $jobOpportunityId: String!
    $limit: Int
    $requiredSkillsToOverride: [EMSISkillInput!]
    $optionalSkillsToOverride: [EMSISkillInput!]
    $distanceToBound: Int
  ) {
    listIdealCandidatesForJob(
      jobOpportunityId: $jobOpportunityId
      limit: $limit
      requiredSkillsToOverride: $requiredSkillsToOverride
      optionalSkillsToOverride: $optionalSkillsToOverride
      distanceToBound: $distanceToBound
    ) {
      hits
    }
  }
`;
